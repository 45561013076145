import React, { useContext, useEffect, useState } from 'react';
import { Badge, Grid, IconButton, List, Typography } from "@mui/material";
import Div from '@jumbo/shared/Div';
import { Form, Formik } from "formik";
import Modal from "@mui/material/Modal";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import { postRequest } from 'backendServices/ApiCalls';
import { useNavigate } from "react-router-dom";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Alert, Stack } from "@mui/material";


const Userpassword = ({ userData }) => {
    const [openUpdate, setOpenUpdate] = useState(false);
    const { loginUserData, setloginUserData } = useContext(CustomProvider);
    const nav = useNavigate()
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })
    // const handleClose = () => setOpen(false);


    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        color: "white",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };
    const validationSchema = yup.object().shape({
        otp: yup
            .string()
            .required("OTP is required"),
        password: yup
            .string()
            .required("Password is required"),
        confirm_password: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),

    });

    const handleSubmit = (data, setSubmitting, resetForm) => {
        let params = {
            oldpassword: data.otp,
            newpassword: data.confirm_password,
        }
        postRequest(
            "/updatedefaultpassword",
            params,
            (response) => {
                console.log("updatedefaultpassword", response)
                if (response?.data?.status === "success") {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    setloginUserData((prevState) => ({
                        ...prevState,
                        passwordupdated: response?.data?.passwordupdated,
                    }));
                    setOpenUpdate(false);
                    nav('/profile');
                } else {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    setOpenUpdate(false);

                }

            },
            (error) => {
                console.log(error?.response?.data);
                setSubmitting(false)

            }
        );
    };

    useEffect(() => {
        if (userData?.passwordupdated === "no" && !openUpdate) {
            setOpenUpdate(true);
        } else if (userData?.profileupdated === "no") {
            nav('/profile');

        }
    }, [userData, openUpdate]);


    return (
        <>
            {alertData.show && (
                <SweetAlert alertData={alertData} setalertData={setalertData} />
            )}
            <Modal
                open={openUpdate}
                onClose={() => setOpenUpdate(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Div sx={style} style={{ minWidth: "600px" }}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Update Password
                    </Typography>
                    <Alert severity="warning">First you need to change your one time password</Alert>
                    <List disablePadding sx={{ mb: 2, mt: 5 }}>
                        <Formik
                            validateOnChange={true}
                            initialValues={{
                                otp: '',
                                password: '',
                                confirm_password: ''

                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                handleSubmit(data, setSubmitting, resetForm);
                                // handleClose();
                            }}
                        >
                            {({ isSubmitting, setFieldValue }) => (
                                <Form
                                    style={{ textAlign: "left", minWidth: "350px" }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} xs={12}>
                                            <Div sx={{ mt: 1, }}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="otp"
                                                    label="OTP (one time password)"
                                                    type="password"
                                                    InputProps={{ style: { color: 'white' } }}
                                                />
                                            </Div>
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <Div sx={{ mt: 1, }}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="password"
                                                    label="New Password"
                                                    type="password"
                                                    InputProps={{ style: { color: 'white' } }}
                                                />
                                            </Div>
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <Div sx={{ mt: 1, mb: 3, }}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="confirm_password"
                                                    label="Confirm Password"
                                                    type="password"
                                                    InputProps={{ style: { color: 'white' } }}
                                                />
                                            </Div>
                                        </Grid>
                                    </Grid>

                                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                        >
                                            Submit
                                        </LoadingButton>
                                    </Div>
                                </Form>
                            )}
                        </Formik>
                    </List>
                </Div>
            </Modal>
        </>

    )
}

export default Userpassword
