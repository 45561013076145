import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Grid, List, MenuItem, Select } from "@mui/material";
import { useState } from 'react';
import { postRequest, registerApi } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';


const validationSchema = yup.object({
    storage_title: yup
        .string()
        .required('Title is required'),
    time: yup
        .string()
        .required('Time is required'),
    storage_price: yup
        .string()
        .required('Price is required'),
});


const Monthlybuy = ({ setOpen, handleLatestData, GetallUsers }) => {
    const [eventData, setEventData] = useState(null);
    const location = useLocation();
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })
    const storageID = location.state?.storageID || '';
    console.log("storageID", storageID.id)


    const handleSubmit = (data, setSubmitting, resetForm) => {
        console.log("hyandleSubmit", data)

        let params = {
            storageid:storageID?.id,
            // storagetitle: data.storage_title,
            price: data.storage_price,
            duration:data.time,
        }
        console.log("params", params)
        postRequest(
            "/buystorage",
            params,
            (response) => {
                if (response?.data?.status === "success") {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    setOpen(false)
                    GetallUsers();
                    resetForm();
                } else {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    // setOpenUpdate(false);

                }

            },
            (error) => {
                console.log(error?.response?.data);
                setSubmitting(false)

            }
        );
    }


    return (
        <Grid container fullWidth sm={12} xs={12} alignItems="center" justifyContent="center" >
            <Grid item sm={6} xs={12} style={{ minWidth: "340px", padding: "5px" }}>
                <JumboCardQuick title={"Monthly Storage"} noWrapper>
                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }

                    <List disablePadding>
                        <Formik
                            validateOnChange={true}
                            enableReinitialize
                            initialValues={{
                                storage_title: storageID?.storagetitle,
                                storage_price: storageID?.monthlyprice,
                                time: 'Monthly',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting, resetForm }) => {
                                console.log("formikdata", data)
                                setSubmitting(true);
                                handleSubmit(data, setSubmitting, resetForm);
                            }}
                        >
                            {({ isSubmitting, setFieldValue }) => (
                                <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>

                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="storage_title"
                                            label="Title"
                                            type="text"
                                        />
                                    </Div>

                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <Select
                                            fullWidth
                                            defaultValue="Monthly"
                                            labelId="select-label"
                                            name="time"
                                            onChange={(event) => {
                                                const selectedValue = event.target.value;
                                                setFieldValue("time", selectedValue);

                                                // Update "storage_price" based on the selected time
                                                if (selectedValue === "Monthly") {
                                                    setFieldValue("storage_price", storageID?.monthlyprice);
                                                } else if (selectedValue === "6 Month") {
                                                    setFieldValue("storage_price", storageID?.sixmonthprice);
                                                } else if (selectedValue === "Yearly") {
                                                    setFieldValue("storage_price", storageID?.yearlyprice);
                                                }
                                            }}
                                        >
                                            <MenuItem value="Monthly">Monthly</MenuItem>
                                            <MenuItem value="6 Month">6 Month</MenuItem>
                                            <MenuItem value="Yearly">Yearly</MenuItem>
                                        </Select>
                                    </Div>

                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="storage_price"
                                            label="Storage Price"
                                            type="text"
                                        />
                                    </Div>



                                    <Div sx={{ mt: 1, pl: 2, pr: 2, }}>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                        >Submit</LoadingButton>
                                    </Div>
                                </Form>
                            )}
                        </Formik>
                    </List>
                </JumboCardQuick>
            </Grid>
        </Grid>
    );
};

export default Monthlybuy;
