import React, {useState, useEffect} from 'react';
import CardHeader from "@mui/material/CardHeader";
import { Card, CardContent, LinearProgress, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { postRequest } from 'backendServices/ApiCalls';

const Storageporfoliobalance = (props) => {
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();
    const [buyStorage, setBuyStorage] = useState();
    const date = new Date(); 
    var day = date.getDay();
    var day1 = date.getDate();
    day = ((day / 7) * 100);
    day1 = ((day1 / 30) * 100);
    const dayp = day + '%';
    const day1p = day1 + '%';

    console.log('buyStorage', buyStorage)

    const GetallUsers = () => {
        postRequest(
            '/getbuystoragedata',
            "",
            (response) => {
                console.log('response', response);
                setBuyStorage(response?.data?.data);
                setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    }

    useEffect(() => {
        GetallUsers();
    }, [])
    if (loading) {
        return (
            <Button>Loading...</Button>
        )
    }
    return (
        <Card>
            <CardHeader title={"Storage"} />
            <CardContent sx={{ pt: 2 }}>
                <Stack direction={"row"} spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant={"h2"}>
                        </Typography>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>Title: </Typography>
                        <Typography variant={"h3"}>{buyStorage[0]?.storagetitle}</Typography>
                        <Stack direction={"row"} spacing={1} sx={{pt:2}}>
                            <Button variant={"contained"} color={"secondary"} component={Link} to={'/storage'}>Buy Storage</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={"h5"} color={"text.secondary"} mb={2}>Storage Duration</Typography>
                        <Typography variant={"h3"} mb={2}>{buyStorage[0]?.duration}</Typography>
                        <Typography variant={"h6"} color={"text.primary"}  sx={{pt:1}}>Price
                            <Typography sx={{ borderLeft: 1, ml: 1, pl: 1 }} component={"span"}
                                color={"text.secondary"}>${buyStorage[0]?.price}</Typography>
                        </Typography>
                        <LinearProgress variant={"determinate"} color={"success"} value={day}
                            sx={{
                                width: { dayp },
                                borderRadius: 4,
                                height: 5,
                                mb: 2,
                                backgroundColor: '#E9EEEF'
                            }}
                        />
                       
                    </Grid>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default Storageporfoliobalance;
