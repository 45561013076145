import React from "react";
import List from "@mui/material/List";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { postRequest, updateProfileData } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import Div from "@jumbo/shared/Div";
import { MenuItem, Select } from "@mui/material";

const validationSchema = yup.object({
  firstname: yup
    .string("Enter User name")
    .required("User name is required"),
  lastname: yup
    .string("Enter last name")
    .required("Full name is required"),
  email: yup
    .string("Enter email address")
    .email("Invalid email address")
    .required("Email is required"),
  gender: yup
    .string("gender name")
    .required("Gender name is required"),
  mobile: yup
    .number("Enter mobile number")
    .required("Mobile is required"),
});


const Passenger = () => {
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const onSubmitForm = (data) => {
    console.log("HandleSubmit", data)
  };

  return (
    <JumboCardQuick title={"Passenger"}>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <List disablePadding>
        <Formik
          validateOnChange={true}
          enableReinitialize="true"
          initialValues={{
            firstname: "",
            lastname: "",
            email: "",
            gender: "male",
            mobile: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            console.log("formikData", data);
            onSubmitForm(data, setSubmitting);
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form style={{ textAlign: "left", minWidth: "500px" }} noValidate autoComplete="off">
              <Div sx={{ pb: 1 }}>
                <JumboTextField
                  fullWidth
                  name="firstname"
                  label="First Name"
                  type="text"
                />
              </Div>

              <Div sx={{ pt: 2, pb: 1 }}>
                <JumboTextField
                  fullWidth
                  name="lastname"
                  label="Last Name"
                  type="text"
                />
              </Div>

              <Div sx={{ pt: 2, pb: 1 }}>
                <JumboTextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                />
              </Div>

              <Div sx={{ pt: 2, pb: 1 }}>
              <Select
                fullWidth
                defaultValue="male"
                labelId="select-label"
                name="selected_fly"
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  setFieldValue("selected_fly", selectedValue);
                }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </Select>
              </Div>

              <Div sx={{ pt: 2, pb: 1 }}>
                <JumboTextField
                  fullWidth
                  label="Mobile"
                  name="mobile"
                  type="number"
                />
              </Div>
              <Div sx={{ pt: 1 }}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </List>
    </JumboCardQuick>
  );
};

export default Passenger;
