import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import { CircularProgress, Grid, List, Modal, Typography } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { Button, DialogContent } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { Dialog } from "@mui/material";
// import Adduser from '../add-users/Adduser';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Flightregistration from "../Flight-registration/Flightregistration";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import ClearIcon from "@mui/icons-material/Clear";

const Flighthistoryregistration = () => {
  const [flightRegistrationData, setFlightRegistrationData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  console.log("flightRegistrationData", flightRegistrationData);
  const dateStr = "2023-10-20T02:15:54.000Z";
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleString();
  const Swal = useSwalWrapper();
  const sweetAlerts = (params) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
      }
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const GetFlightRegistration = () => {
    postRequest(
      "/getflightregistration",
      "",
      (response) => {
        setisLoading(true);
        const proceedData = response?.data?.data?.entries.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        setFlightRegistrationData(proceedData);
      },
      (error) => {
        console.log(error?.response?.data);
        setisLoading(false);
      }
    );
  };

  useEffect(() => {
    GetFlightRegistration();
  }, []);

  const handleOpen = (id) => {
    const rowToEdit = flightRegistrationData.find((row) => row.id === id);
    setRowData(rowToEdit);
    setOpenUpdate(true);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 100,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 100,
      editable: true,
      groupable: false,
    },
    {
      field: "personfly",
      headerName: "Person Fly",
      width: 100,
      editable: true,
      groupable: false,
    },

    {
      field: "passengerdetail",
      headerName: "Passenger Detail",
      width: 200,
      editable: true,
      groupable: false,
      renderCell: (params) => {
        const { personfly } = params.row;

        if (personfly === "passenger") {
          return (
            <Button
              variant="contained"
              style={{ cursor: "pointer", color: "white" }}
              onClick={() => handleOpen(params.row.id)}
            >
              Passenger Detail
            </Button>
          );
        } else {
          return <Div sx={{ m: "auto" }}>N/A</Div>; // Display "N/A" for rows where personfly is not "passenger"
        }
      },
    },

    {
      field: "signature",
      headerName: "Signature",
      width: 100,
      editable: true,
      groupable: false,
    },
    {
      field: "dateandtime",
      headerName: "Date And Time",
      width: 200,
      editable: true,
      groupable: false,
      renderCell: (params) => {
        const dateStr = params.row.dateandtime;
        const date = new Date(dateStr);
        const formattedDate = date.toLocaleString(); // Format the date to the user's locale
        return formattedDate;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      editable: true,
      groupable: false,
    },
  ];

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  // if (isLoading) {
  //   return (
  //     <Div
  //       sx={{
  //         display: "flex",
  //         minWidth: 0,
  //         alignItems: "center",
  //         alignContent: "center",
  //         height: "100%",
  //       }}
  //     >
  //       <CircularProgress sx={{ m: "-40px auto 0" }} />
  //     </Div>
  //   );
  // }

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Manage Registration History"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

          <Box sx={{ height: 500, width: 1 }}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-40px",
                pb: 3,
              }}
            >
              <Grid item sm={3} xs={12}>
                <Button
                  variant="contained"
                  sx={{ mt: 4 }}
                  onClick={() => setOpen(true)}
                >
                  Flight Registration
                </Button>
              </Grid>
            </Grid>
            <Div>
              <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                  <Flightregistration open={open} setOpen={setOpen} GetFlightRegistration={GetFlightRegistration}/>
                </DialogContent>
              </Dialog>
            </Div>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={flightRegistrationData}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
          <Modal
            open={openUpdate}
            // onClick={handleModalClick}
            // onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Div sx={style} style={{ minWidth: "600px" }}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Passenger Record
              </Typography>
              <Grid item sm={12}>
                <ClearIcon
                  onClick={() => setOpenUpdate(false)}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    position: "absolute",
                    top: 3,
                    right: 3,
                    zIndex: 1000,
                  }}
                  sx={{ float: "right" }}
                />
              </Grid>
              <List disablePadding sx={{ mb: 2, mt: 5 }}>
                <Formik
                  validateOnChange={true}
                  initialValues={{
                    passengerfirstname: rowData?.passengerfirstname || "",
                    passengerlastname: rowData?.passengerlastname || "",
                    passengeremail: rowData?.passengeremail || "",
                    passengermobile: rowData?.passengermobile || "",
                    passengergender: rowData?.passengergender || "",
                  }}
                  // validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting, resetForm }) => {
                    // setSubmitting(true);
                    // handleUpdate(data, setSubmitting, resetForm);
                    // handleClose();
                  }}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form
                      style={{ textAlign: "left", minWidth: "350px" }}
                      noValidate
                      autoComplete="off"
                    >
                      <Grid item sm={12}>
                        <Div sx={{ mt: 1, mb: 3 }}>
                          <JumboTextField
                            fullWidth
                            name="passengerfirstname"
                            label="First Name"
                            type="text"
                            InputProps={{
                              readOnly: "true",
                            }}
                          />
                        </Div>
                      </Grid>
                      <Grid item sm={12}>
                        <Div sx={{ mt: 1, mb: 3 }}>
                          <JumboTextField
                            fullWidth
                            name="passengerlastname"
                            label="Last Name"
                            type="text"
                            InputProps={{
                              readOnly: "true",
                            }}
                          />
                        </Div>
                      </Grid>

                      <Grid item sm={12}>
                        <Div sx={{ mt: 1, mb: 3 }}>
                          <JumboTextField
                            fullWidth
                            name="passengeremail"
                            label="Email"
                            type="text"
                            InputProps={{
                              readOnly: "true",
                            }}
                          />
                        </Div>
                      </Grid>
                      <Grid item sm={12}>
                        <Div sx={{ mt: 1, mb: 3 }}>
                          <JumboTextField
                            fullWidth
                            name="passengermobile"
                            label="Mobile"
                            type="number"
                            InputProps={{
                              readOnly: "true",
                            }}
                          />
                        </Div>
                      </Grid>

                      <Grid item sm={12}>
                        <Div sx={{ mt: 1, mb: 3 }}>
                          <JumboTextField
                            fullWidth
                            name="passengergender"
                            label="Gender"
                            type="text"
                            InputProps={{
                              readOnly: "true",
                            }}
                          />
                        </Div>
                      </Grid>

                      {/* <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Div> */}
                    </Form>
                  )}
                </Formik>
              </List>
            </Div>
          </Modal>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Flighthistoryregistration;
