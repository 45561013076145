import React, { useEffect } from "react";
import List from "@mui/material/List";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useState } from "react";
import { countries } from "app/pages/components/mui/AutoCompletes/data";
import { useContext } from "react";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { postRequest } from "backendServices/ApiCalls";
import { format, parseISO } from "date-fns";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Alert } from "@mui/material";



const validationSchema = yup.object({
  mobile: yup
    .number('Enter mobile number')
    .required('Mobile is required'),
    
  emergency_contact_name: yup
    .string("Emergency contact name")
    .required("Emergency contact name is required"),
  emergency_contact_relationship: yup
    .string("emergency contact relationship")
    .required("Emergency  Contact Relationship is required"),
  emergency_contact_mobile_number: yup
    .number("emergency_contact_mobile_number")
    .required("Emergency contact mobile number is required")
    .test('notEqual', 'emergency contact must be different from  mobile number', function (value) {
      const mobile = this.parent.mobile;
      // Check if "mobile" and "emergency_contact_mobile_number" are both defined and have the same value.
      if (value && mobile && value === mobile) {
        return false; // Return false to indicate the validation failed.
      }
      return true;
    }),
  pilot_license_type: yup
    .string("Pilot license type")
    .required("Pilot license type is required"),
  pilot_license_category: yup
    .string("pilot license  category")
    .required("Pilot license Category is required"),
  // pilot_license_expiry_date: yup
  //     .string('pilot_license_expiry_date')
  //     .required('Pilot license expry date is required'),

  pilot_license_number: yup
    .number("pilot license number")
    .required("Pilot license Number is required"),
  policy_number: yup
    .number("Policy number")
    .required("Policy Number is required"),

  // expiration_date: yup
  //     .string('expiration date')
  //     .required('Expiration date is required'),
  wing_brand: yup.string("wing brand").required("wing brand is required"),
  wing_type: yup.string("wing type").required("wing type is required"),
  wing_serial_number: yup
    .number("wing serial number")
    .required("wing serial number is required"),
  wing_registration_number: yup
    .number("wing registration number")
    .required("wing registeration number is required"),
  wing_colors: yup.string("wing color").required("wing colorr is required"),
  paramotor_brand: yup
    .string("paramotor brand")
    .required("paramotor brand is required"),
  motor_type: yup.string("motor type").required("motor type is required"),
  moter_serial_number: yup
    .number("moter serial number")
    .required("moter serial number is required"),
});

const About = () => {
  const [country, setCountry] = useState("");
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  let userData = loginUserData;
  console.log("useData", userData)
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [usersProfileData, setUsersProfileData] = useState([]);
  const [formattedPilotLicenseExpiryDate1, setFormattedPilotLicenseExpiryDate] =
    useState("");
  const [
    formattedInsuranceExpirationDate1,
    setFormattedInsuranceExpirationDate,
  ] = useState("");
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const UsersDataProfile = () => {
    postRequest(
      "/getbloodgroup",
      "",
      (response) => {
        if (response?.data?.status === "success") {
          setUsersProfileData(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const onSubmitForm = (data, setSubmitting) => {
    let params = {
      // username: data.username,
      // fullname: data.fullname,
      // email: data.email,
      mobile: data.mobile,
      bloodgroup: data.blood_group,
      emergencycontactname: data.emergency_contact_name,
      emergencycontactrelationship: data.emergency_contact_relationship,
      emergencycontactmobile: data.emergency_contact_mobile_number,
      pilotlicensetype: data.pilot_license_type,
      pilotlicensecategory: data.pilot_license_category,
      pilotlicensenumber: data.pilot_license_number,
      pilotlicenseexpirydate: formattedPilotLicenseExpiryDate1,
      Insurancepolicynumber: data.policy_number,
      Insuranceexpirationdate: formattedInsuranceExpirationDate1,
      wingbrand: data.wing_brand,
      wingtype: data.wing_type,
      wingserialnumber: data.wing_serial_number,
      wingregistrationnumber: data.wing_registration_number,
      wingcolors: data.wing_colors,
      paramotorbrand: data.paramotor_brand,
      motortype: data.motor_type,
      serialnumber: data.moter_serial_number,
    };
    postRequest(
      "/updateprofiledata",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setSubmitting(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
          // setloginUserData();
          // setOpenUpdate(false);
          // nav('/profile');
        } else {
          setSubmitting(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
          // setOpenUpdate(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setSubmitting(false);
      }
    );
  };
  useEffect(() => {
    if (userData && userData.country) {
      const country = countries.find(
        (country) =>
          country.label.toLowerCase() === userData?.country.toLowerCase()
      );
      setSelectedCountry(country);
    }
    UsersDataProfile();
  }, [userData, countries]);

  useEffect(() => {
    if (userData?.pilotlicenseexpirydate) {
      try {
        setFormattedPilotLicenseExpiryDate(
          format(parseISO(userData.pilotlicenseexpirydate), "yyyy-MM-dd")
        );
      } catch (error) {
        console.error("Error parsing pilot license expiry date:", error);
      }
    }

    if (
      userData?.Insuranceexpirationdate &&
      userData.Insuranceexpirationdate !== "0000-00-00"
    ) {
      try {
        setFormattedInsuranceExpirationDate(
          format(parseISO(userData.Insuranceexpirationdate), "yyyy-MM-dd")
        );
      } catch (error) {
        console.error("Error parsing insurance expiration date:", error);
      }
    } else {
      // Handle the case where Insuranceexpirationdate is '0000-00-00', e.g., set a default value or show a message.
      // For example:
      setFormattedInsuranceExpirationDate("N/A");
    }

    UsersDataProfile();
  }, [userData, countries]);

  if (userData?.firstname === "") {
    return <div>laoding</div>;
  }

  return (
    <JumboCardQuick title={"Update Profile"}>
      {userData?.profileupdated === "no" &&
        <Box sx={{ mb: 5 }}>
          <Alert severity="warning">First you need to fill your profile form</Alert>
        </Box>
      }

      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <List disablePadding>


        <Formik
          validateOnChange={true}
          enableReinitialize="true"
          initialValues={{
            username: userData?.username || "",
            fullname: `${userData?.firstname || ""} ${userData?.lastname || ""
              }`,
            email: userData?.email || "",
            mobile: userData?.mobile || "",
            blood_group: userData?.bloodgroup || "",
            emergency_contact_name: userData?.emergencycontactname || "",
            emergency_contact_relationship:
              userData?.emergencycontactrelationship || "",
            emergency_contact_mobile_number:
              userData?.emergencycontactmobile || "",
            pilot_license_type: userData?.pilotlicensetype || "",
            pilot_license_category: userData?.pilotlicensecategory || "",
            pilot_license_number: userData?.pilotlicensenumber || "",
            pilot_license_expiry_date: userData?.pilotlicenseexpirydate || "",
            policy_number: userData?.Insurancepolicynumber || "",
            expiration_date: userData?.expiration_date || "",
            wing_brand: userData?.wingbrand || "",
            wing_type: userData?.wingtype || "",
            wing_serial_number: userData?.wingserialnumber || "",
            wing_registration_number: userData?.wingregistrationnumber || "",
            wing_colors: userData?.wingcolors || "",
            paramotor_brand: userData?.paramotorbrand || "",
            motor_type: userData?.motortype || "",
            moter_serial_number: userData?.serialnumber || "",
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            onSubmitForm(data, setSubmitting);
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item sm={4} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="username"
                    label="User Name"
                    type="text"
                    InputProps={{
                      readOnly: "true",
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="fullname"
                    label="Full Name"
                    type="text"
                    InputProps={{
                      readOnly: "true",
                    }}
                  />
                </Grid>

                <Grid item sm={4} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    InputProps={{
                      readOnly: "true",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    type="number"
                  />
                </Grid>

                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Blood Group</InputLabel>
                    <Select
                      fullWidth
                      id="demo-simple-select"
                      labelId="select-label"
                      name="blood_group"
                      value={values.blood_group}
                      onChange={(event) => {
                        const selectedvalue = event.target.value;
                        setFieldValue("blood_group", selectedvalue); // Update the form field value
                      }}
                    >
                      {usersProfileData?.map((row, index) => (
                        <MenuItem key={index} value={row.bloodgroupname}>
                          {row?.bloodgroupname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item sm={4} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label="Emergency contact name"
                    name="emergency_contact_name"
                    type="text"
                  />
                </Grid>
                <Grid item sm={4} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label="Emergency contact relationship"
                    name="emergency_contact_relationship"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <JumboTextField
                    fullWidth
                    label="Emergency mobile number"
                    name="emergency_contact_mobile_number"
                    type="number"
                  />
                </Grid>
              </Grid>

              <h3 style={{ paddingBottom: "22px" }}>License related:</h3>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    label="Pilot license type"
                    name="pilot_license_type"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    name="pilot_license_category"
                    label="Pilot license category "
                    type="text"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    label="Pilot license number"
                    name="pilot_license_number"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    name="pilot_license_expiry_date"
                    label="Pilot license expiry date "
                    type="date"
                    InputProps={{
                      value: formattedPilotLicenseExpiryDate1,
                      onChange: (event) => {
                        const newDate = event.target.value;
                        setFormattedPilotLicenseExpiryDate(newDate);
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <h3 style={{ paddingBottom: "22px" }}>Insurance related:</h3>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    label="Policy number"
                    name="policy_number"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    name="expiration_date"
                    label="Expiration date "
                    type="date"
                    InputProps={{
                      value: formattedInsuranceExpirationDate1,
                      // onChange: handleExpirationDateChange,
                      onChange: (event) => {
                        const newDate = event.target.value;
                        setFormattedInsuranceExpirationDate(newDate);
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <h3 style={{ paddingBottom: "22px" }}>My equipment:</h3>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    label="Wing brand"
                    name="wing_brand"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    name="wing_type"
                    label="Wing type"
                    type="text"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    label="Wing serial number"
                    name="wing_serial_number"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    name="wing_registration_number"
                    label="Wing registration number "
                    type="number"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    label="Wing colors"
                    name="wing_colors"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    name="paramotor_brand"
                    label="Paramotor brand"
                    type="text"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    label="Motor type"
                    name="motor_type"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    fullWidth
                    name="moter_serial_number"
                    label="Serial number"
                    type="number"
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={6}
                sx={{ width: { xs: "100%" }, justifyContent: "end" }}
              >
              {userData?.profileupdated === "yes" ? (
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                  disabled
                >
                  Submit
                </LoadingButton>
              ) : (
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              )}
              </Grid>
              {/* </Grid> */}
            </Form>
          )}
        </Formik>
      </List>
    </JumboCardQuick>
  );
};

export default About;