import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  LinearProgress,
  List,
  TextField,
  Typography,
} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { postRequest, uploadDocuments } from "backendServices/ApiCalls";

const UploadDocument = () => {
  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const initialUploadState = {
    files: [null, null, null, null, null, null, null, null, null, null],
    uploading: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    uploadProgress: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  };

  const [uploadState, setUploadState] = useState(initialUploadState);
  const [uploadedImageUrls, setUploadedImageUrls] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const documentTypes = [
    "pilotlicensecard",
    "insurancepolicy",
    "insurancecertificate",
    "mypicture",
    "mysecurityclearance",
    "mypassportcopy",
    "myeidcopy",
    "liabilitywaiver",
    "medicalfitnessdeclaration",
    "rulesandregulationsagreement",
  ];

  const fileLabel = [
    "Pilot License Card",
    "Insurance Policy",
    "Insurance Certificate",
    "My Picture",
    "My Security Clearance",
    "My Passport Copy",
    "My EID Copy",
    "Liability Waiver",
    "Medical Fitness Declaration",
    "Rules And Regulations Agreement",
  ];

  const [imageNames, setImageNames] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
  });
  console.log("imagename", imageNames);
  const handleUpload = (e, index) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result;
          const formData = new FormData();

          formData.append("documentType", documentTypes[index]);
          formData.append("image", base64Data);

          const newUploadState = { ...uploadState };
          newUploadState.uploading[index] = true;
          newUploadState.uploadProgress[index] = 0;
          setUploadState(newUploadState);

          uploadDocuments(
            "/uploaddocumentimages",
            formData,
            (response) => {
              const newUploadState = { ...uploadState };
              newUploadState.uploading[index] = true;
              setUploadState(newUploadState);
              console.log("response", response);
              if (response?.data?.status === "success") {
                setAlertData({
                  show: true,
                  message: response?.data?.message,
                  variant: "success",
                });
                setImageNames({
                  ...imageNames,
                  [index]: response?.data?.imagename,
                });
                // Set the uploaded image URL
                const newUploadedImageUrls = [...uploadedImageUrls];
                newUploadedImageUrls[index] = response?.data?.pictureurl;
                setUploadedImageUrls(newUploadedImageUrls);
              } else if (response?.data?.status === "error") {
                setAlertData({
                  show: true,
                  message: response?.data?.message,
                  variant: "error",
                });
              } else {
                setAlertData({
                  show: true,
                  message: "Something went wrong. Please try again later",
                  variant: "error",
                });
              }
            },
            (error) => {
              console.log(error?.response?.data);
              const newUploadState = { ...uploadState };
              newUploadState.uploading[index] = false;
              setUploadState(newUploadState);

              setAlertData({
                show: true,
                message: "Something went wrong. Please try again",
                variant: "error",
              });
            },
            (progressEvent) => {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              const newUploadState = { ...uploadState };
              newUploadState.uploadProgress[index] = progress;
              if (progress === 100) {
                console.log("condition good");
              }
              setUploadState(newUploadState);
            }
          );
        };

        reader.readAsDataURL(selectedFile);
      } else {
        setAlertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
      }
    }
  };

  const handleSubmit = () => {
    const params = {
      documentTypes: documentTypes,
      imageNames: imageNames,
    };
    console.log("params", params);

    postRequest(
      "/uploaddocument",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setAlertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
        } else {
          setAlertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        }
      },
      (error) => {
        console.error("Error: " + error?.response?.data);
      }
    );
  };

  const fileInputsAndProgressBars = uploadState.files.map((file, index) => (
    
      <Grid item sm={6}>
        <List>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              "& .MuiTextField-root": { width: "34ch" },
              marginLeft: 5,
            }}
            alignItems="center"
          >
            <div key={index} style={{ textAlign: "left" }}>
              <Typography variant="body1" color="white">{fileLabel[index]}</Typography>
              <TextField
                name={`file${index}`}
                type="file"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <CameraAltIcon fontSize={"small"} color={"success"} />
                  ),
                }}
                onChange={(e) => handleUpload(e, index)}
              />
              {uploadState.uploading[index] && !uploadedImageUrls[index] && (
                <CircularProgress />
              )}
              {/* <LinearProgress
        key={`progress${index}`}
        variant="determinate"
        value={uploadState.uploadProgress[index]}
        style={{ display: uploadState.uploading[index] ? "block" : "none" }}
      /> */}
              {uploadedImageUrls[index] && (
                <>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <a
                        href={uploadedImageUrls[index]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          image={uploadedImageUrls[index]}
                          alt={`Uploaded ${documentTypes[index]}`}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {fileLabel[index]}
                          </Typography>
                        </CardContent>
                      </a>
                    </CardActionArea>
                  </Card>
                  {/* <img
          src={uploadedImageUrls[index]}
          alt={`Uploaded ${documentTypes[index]}`}
          style={{ maxWidth: "100px" }}
        /> */}
                </>
              )}
            </div>
          </Box>
        </List>
      </Grid>
  ));

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item sm={12} textAlign={"center"}>
        {alertData.show && (
          <SweetAlert alertData={alertData} setAlertData={setAlertData} />
        )}
        <JumboCardQuick title={"Upload Document"} noWrapper>
        <Grid container spacing={2}>
          {fileInputsAndProgressBars}
          </Grid>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mb: 2, ml: 4 }}
          >
            Submit
          </Button>
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default UploadDocument;
