import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as yup from "yup";
import Div from "@jumbo/shared/Div/Div";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Dialog, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Passenger from "../passenger-type/Passenger";
import Modal from "@mui/material/Modal";
import { postRequest } from "backendServices/ApiCalls";
import ClearIcon from '@mui/icons-material/Clear';

const validationSchema = Yup.object().shape({
  equipment: Yup.boolean().oneOf([true], "Equipment Checkbox is required").required(),
  rulesandregulations: Yup.boolean().oneOf([true], "Checkbox2 is required").required(),
  gaggletracker: Yup.boolean().oneOf([true], "Checkbox3 is required").required(),
  carryaradio: Yup.boolean().oneOf([true], "Checkbox4 is required").required(),
  signature: Yup.string().required("Signature is required"),
});
const validationSchemas = yup.object({
  passengerfirstname: yup.string("Enter User name").required("User name is required"),
  passengerlastname: yup.string("Enter last name").required("Full name is required"),
  passengeremail: yup
    .string("Enter email address")
    .email("Invalid email address")
    .required("Email is required"),
    passengergender: yup.string("Gender name").test('is-selected', 'Gender is required', (value) => {
      return value !== "Gender"; // Ensure that a gender other than the default value "Gender" is selected.
    }),
  passengermobile: yup.number("Enter mobile number").required("Mobile is required"),
});

const Flightregistration = ({ open, setOpen, handleLatestData, GetFlightRegistration }) => {
  const [value, setValue] = React.useState(new Date("2023-06-04T21:11:54"));
  const [openUpdate, setOpenUpdate] = useState(false);
  const [passengerData, setPassengerData] = useState(null);
  const [selectedFly, setSelectedFly] = useState("solo");
  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const handleSubmit = (data, setSubmitting, resetForm ) => {
    let params = {
      ...data,
      ...passengerData,
      equipment: data.equipment,
      rulesandregulations: data.rulesandregulations,
      gaggletracker: data.gaggletracker,
      carryaradio: data.carryaradio,
    }
    postRequest(
      "/addflightregistration",
      params,
      (response) => {
          if (response?.data?.status === "success") {
              setSubmitting(false)
              setAlertData({
                  show: true,
                  message: response?.data?.message,
                  variant: response?.data?.status
              })
              setOpenUpdate(false);
              setOpen(false);
              GetFlightRegistration();
          } else {
              setSubmitting(false)
              setAlertData({
                  show: true,
                  message: response?.data?.message,
                  variant: response?.data?.status
              })
              setOpenUpdate(false);

          }

      },
      (error) => {
          console.log(error?.response?.data);
          setSubmitting(false)

      }
  );
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    color: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const openPassengerModal = () => {
    setSelectedFly("passenger");
    setOpenUpdate(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Div sx={style} style={{ minWidth: "600px" }}>
          <Formik
            validateOnChange={true}
            enableReinitialize={true}
            initialValues={{
              dateandtime: value,
              personfly: "solo",
              equipment: false,
              rulesandregulations: false,
              gaggletracker: false,
              carryaradio: false,
              signature: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              handleSubmit(data, setSubmitting, resetForm);
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form
                style={{ textAlign: "left", minWidth: "250px" }}
                noValidate
                autoComplete="off"
              >
                            <ClearIcon onClick={() => setOpen(false)} style={{cursor: "pointer", color: "white", position: "absolute", right: 0, top: 0,   zIndex: 1000}} sx={{ float: "right" }} />
                <h2 style={{ paddingBottom: "20px" }}>Flight Registration</h2>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item sm={6} xs={12}>
                    <Div style={{ paddingLeft: 10 }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        fullWidth
                      >
                        <DateTimePicker
                          fullWidth
                          name="dateandtime"
                          label="Date & Time"
                          value={values.dateandtime} // Bind to form field value
                          onChange={(newValue) =>
                            setFieldValue("dateandtime", newValue)
                          } // Update form field value
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Div>
                  </Grid>
                  <Grid sm={6} xs={12} sx={{ mt: 2, pl: 2 }}>
                    <Select
                      fullWidth
                      defaultValue="solo"
                      labelId="select-label"
                      name="personfly"
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        setSelectedFly(selectedValue);
                        setFieldValue("personfly", selectedValue);
                        if (selectedValue === "passenger") {
                          openPassengerModal();
                        }
                      }}
                    >
                      <MenuItem value="solo">Solo</MenuItem>
                      <MenuItem value="passenger">Passenger</MenuItem>
                    </Select>
                    <ErrorMessage
                      name="personfly"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </Grid>
                </Grid>
                <Div sx={{ mt: 2, pl: 1 }}>
                  <label>
                    <Field
                      type="checkbox"
                      name="equipment"
                      required
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "6px",
                      }}
                    />
                    I have conducted a pre check of my equipment and found it
                    airworthy
                  </label>
                </Div>
                <ErrorMessage
                  name="equipment"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />

                <Div sx={{ mt: 2, pl: 1 }}>
                  <label>
                    <Field
                      type="checkbox"
                      name="rulesandregulations"
                      required
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "6px",
                      }}
                    />
                    I will respect all applicable rules and regulations and
                    follow instructions as given by field officer and SAAC
                    management at all times
                  </label>
                </Div>
                <ErrorMessage
                  name="rulesandregulations"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />

                <Div sx={{ mt: 2, pl: 1 }}>
                  <label>
                    <Field
                      type="checkbox"
                      name="gaggletracker"
                      required
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "6px",
                      }}
                    />
                    I will activate Gaggle tracker for flight recording and
                    ensure my mobile device is properly charged
                  </label>
                </Div>
                <ErrorMessage
                  name="gaggletracker"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />

                <Div sx={{ mt: 2, pl: 1 }}>
                  <label>
                    <Field
                      type="checkbox"
                      name="carryaradio"
                      required
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "6px",
                      }}
                    />
                    I will carry a radio with frequency 142.250 and zello
                    communication with properly charged batteries
                  </label>
                </Div>
                <ErrorMessage
                  name="carryaradio"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />

                <Div sx={{ mt: 2, pl: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="signature"
                    label="Add Signature"
                    type="text"
                  />
                </Div>
                <Div sx={{ mt: 2, pl: 1 }}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    // sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Div>
                {alertData.show && (
                  <SweetAlert
                    alertData={alertData}
                    setAlertData={setAlertData}
                  />
                )}
              </Form>
            )}
          </Formik>
        </Div>
      </Modal>
      {selectedFly === "passenger" && (
        <Modal
          open={openUpdate}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Div sx={style} style={{ minWidth: "600px", marginTop:"-20px", }}>

            <Formik
              validateOnChange={true}
              enableReinitialize="true"
              initialValues={{
                passengerfirstname: "",
                passengerlastname: "",
                passengeremail: "",
                passengergender: "",
                passengermobile: "",
              }}
              validationSchema={validationSchemas}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                setPassengerData(data);
                setOpenUpdate(false);
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form
                  style={{ textAlign: "left", minWidth: "500px",  }}
                  noValidate
                  autoComplete="off"
                >
                            <ClearIcon onClick={() => setOpenUpdate(false)} style={{cursor: "pointer", color: "white", position: "absolute", right: 0, top: 0,   zIndex: 1000}} sx={{ float: "right" }} />

                  <Div sx={{ pb: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="passengerfirstname"
                      label="First Name"
                      type="text"
                    />
                  </Div>

                  <Div sx={{ pt: 2, pb: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="passengerlastname"
                      label="Last Name"
                      type="text"
                    />
                  </Div>

                  <Div sx={{ pt: 2, pb: 1 }}>
                    <JumboTextField
                      fullWidth
                      label="Email"
                      name="passengeremail"
                      type="email"
                    />
                  </Div>

                  <Div sx={{ pt: 2, pb: 1 }}>
                  <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Select
                      fullWidth
                      label="Gender"
                      labelId="demo-simple-select-label"
                      name="passengergender"
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        setFieldValue("passengergender", selectedValue);
                      }}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                    </FormControl>
                  </Div>

                  <Div sx={{ pt: 2, pb: 1 }}>
                    <JumboTextField
                      fullWidth
                      label="Mobile"
                      name="passengermobile"
                      type="number"
                    />
                  </Div>
                  <Div sx={{ pt: 1 }}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                      sx={{ mb: 3 }}
                    >
                      Submit
                    </LoadingButton>
                  </Div>
                </Form>
              )}
            </Formik>
          </Div>
        </Modal>
      )}
    </>
  );
};

export default Flightregistration;
