import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PricePlan from "./PricePlan";
import { postRequest } from "backendServices/ApiCalls";
import { useState, useEffect } from "react";
import SweetAlert from "../components/mui/Alerts/SweetAlert";

const Membershipplan = () => {
  const [memberShipPlan, setMemberShipPlan] = useState([]);
  const [buyMemberships, setBuyMembership] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  // Define the GetMemberShip function
  const Getbuymembership = () => {
    postRequest(
      "/getonemembershipreports",
      "",
      (response) => {
        setBuyMembership(response?.data?.data);
        setLoading(false);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
        console.log('response', response)
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  const GetMemberShip = () => {
    postRequest(
      "/getmembership",
      "",
      (response) => {
        setMemberShipPlan(response?.data?.data);
        setLoading(false);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  // Define the buyMembership function
  const buyMembership = (id, time, price) => {
    const params = {
      membershipid: id,
      duration: time,
      price: price,
    };
    postRequest(
      "/buymembership",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
          GetMemberShip();
          Getbuymembership();
        } else {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetMemberShip();
    Getbuymembership();
  }, []);

  if (loading) {
    return <h1>Loading ...</h1>;
  }
  return (
    <React.Fragment>
      <Typography variant="h2" mb={4}>
        Membership
      </Typography>
      <Grid container spacing={3.75} mb={4}>
        {alertData.show && (
          <SweetAlert alertData={alertData} setalertData={setalertData} />
        )}
        <Grid item xs={12} md={6} lg={4}>
          <PricePlan
            title={`$${memberShipPlan[0]?.price}`}
            subheader={memberShipPlan[0]?.time}
            headerSx={{
              textAlign: "center",
              bgcolor: "primary.main",
            }}
          >
            <List disablePadding sx={{ mb: 4 }}>
              <ListItem>
                <ListItemIcon
                  sx={{ minWidth: 32, color: "inherit" }}
                ></ListItemIcon>
                <ListItemText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: memberShipPlan[0]?.detail
                        .replace(/\\n/g, "")
                        .replace(/\\/g, "")
                        .trim(),
                    }}
                  />
                </ListItemText>
              </ListItem>
            </List>
            {buyMemberships[0]?.duration === "Monthly" ||
            buyMemberships[0]?.duration === "6 Month" ||
            buyMemberships[0]?.duration === "Yearly" ? (
              <Button
                variant={"contained"}
                disableElevation
                sx={{ mb: 2 }}
                onClick={() => {
                  buyMembership(
                    memberShipPlan[0]?.id,
                    memberShipPlan[0]?.time,
                    memberShipPlan[0]?.price
                  );
                }}
                disabled
              >
                Buy Now
              </Button>
            ) : (
              <Button
                variant={"contained"}
                disableElevation
                sx={{ mb: 2 }}
                onClick={() => {
                  buyMembership(
                    memberShipPlan[0]?.id,
                    memberShipPlan[0]?.time,
                    memberShipPlan[0]?.price
                  );
                }}
              >
                Buy Now
              </Button>
            )}
          </PricePlan>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PricePlan
            title={`$${memberShipPlan[1]?.price}`}
            subheader={memberShipPlan[1]?.time}
            headerSx={{
              textAlign: "center",
              bgcolor: "primary.main",
            }}
          >
            <List disablePadding sx={{ mb: 4 }}>
              <ListItem>
                <ListItemIcon
                  sx={{ minWidth: 32, color: "inherit" }}
                ></ListItemIcon>
                <ListItemText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: memberShipPlan[1]?.detail
                        .replace(/\\n/g, "")
                        .replace(/\\/g, "")
                        .trim(),
                    }}
                  />
                </ListItemText>
              </ListItem>
            </List>
            {buyMemberships[0]?.duration === "6 Month" ||
            buyMemberships[0]?.duration === "Yearly" ? (
              <Button
                variant={"contained"}
                disableElevation
                sx={{ mb: 2 }}
                onClick={() => {
                  buyMembership(
                    memberShipPlan[1]?.id,
                    memberShipPlan[1]?.time,
                    memberShipPlan[1]?.price
                  );
                }}
                disabled
              >
                Buy Now
              </Button>
            ) : (
              <Button
                variant={"contained"}
                disableElevation
                sx={{ mb: 2 }}
                onClick={() => {
                  buyMembership(
                    memberShipPlan[1]?.id,
                    memberShipPlan[1]?.time,
                    memberShipPlan[1]?.price
                  );
                }}
              >
                Buy Now
              </Button>
            )}
          </PricePlan>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PricePlan
            title={`$${memberShipPlan[2]?.price}`}
            subheader={memberShipPlan[2]?.time}
            headerSx={{
              textAlign: "center",
              bgcolor: "primary.main",
            }}
          >
            <List disablePadding sx={{ mb: 4 }}>
              <ListItem>
                <ListItemIcon
                  sx={{ minWidth: 32, color: "inherit" }}
                ></ListItemIcon>
                <ListItemText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: memberShipPlan[2]?.detail
                        .replace(/\\n/g, "")
                        .replace(/\\/g, "")
                        .trim(),
                    }}
                  />
                </ListItemText>
              </ListItem>
            </List>
            {buyMemberships[0]?.duration === "Yearly" ? (
              <Button
                variant={"contained"}
                disableElevation
                sx={{ mb: 2 }}
                onClick={() => {
                  buyMembership(
                    memberShipPlan[2]?.id,
                    memberShipPlan[2]?.time,
                    memberShipPlan[2]?.price
                  );
                }}
                disabled
              >
                Buy Now
              </Button>
            ) : (
              <Button
                variant={"contained"}
                disableElevation
                sx={{ mb: 2 }}
                onClick={() => {
                  buyMembership(
                    memberShipPlan[2]?.id,
                    memberShipPlan[2]?.time,
                    memberShipPlan[2]?.price
                  );
                }}
              >
                Buy Now
              </Button>
            )}
          </PricePlan>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Membershipplan;
