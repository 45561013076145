import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Span from "@jumbo/shared/Span";
import PricePlan from "./PricePlan";
import FeaturesList from "./FeaturesList";
import { useTranslation } from "react-i18next";
import { postRequest } from "backendServices/ApiCalls";
import { useNavigate } from "react-router-dom";

const Storage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [storage, setStorage] = useState();
  const [loading, setLoading] = useState(true);

  const GetStorage = () => {
    postRequest(
      "/getstorage",
      "",
      (response) => {
        const processedData = response?.data?.data?.map((row, index) => ({
          ...row,
          index: index + 1,
          // id: row?.userid
        }));
        setStorage(processedData);
        setLoading(false);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetStorage();
  }, []);

  if (loading) {
    return <h1>Loading ...</h1>;
  }
  return (
    <Grid container spacing={2} mb={4}>
      {storage?.map((value, index) => (
        <Grid item key={index} xs={12} sm={4}>
          <PricePlan
            title={
              <Typography
                variant={"h5"}
                color="inherit"
                sx={{
                  textTransform: "uppercase",
                  letterSpacing: 3,
                }}
              >
                {value?.storagetitle}
              </Typography>
            }
            subheader={
              <Typography
                variant={"h2"}
                fontSize={52}
                fontWeight={500}
                mb={0}
                color="inherit"
              >
                <Span
                  sx={{
                    fontSize: "50%",
                    verticalAlign: "super",
                    fontWeight: "400",
                    mr: 0.5,
                  }}
                >
                  $
                </Span>
                {value?.monthlyprice}
              </Typography>
            }
            headerSx={{
              textAlign: "center",
              bgcolor: "primary.main",
            }}
          >
            <Button
              variant={"contained"}
              disableElevation
              onClick={() =>
                navigate("/monthly-buy", {
                  state: {
                    storageID: value,
                    storageTitle: value?.storagetitle,
                  },
                })
              }
            >
              Buy Now
            </Button>
          </PricePlan>
        </Grid>
      ))}
    </Grid>
  );
};

export default Storage;
