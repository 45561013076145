import React, { useState, useEffect } from 'react';
import CardHeader from "@mui/material/CardHeader";
import { Card, CardActions, CardContent, LinearProgress, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { postRequest } from 'backendServices/ApiCalls';

const PortfolioBalance = (props) => {
    const [memberShipPlan, setMemberShipPlan] = useState();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const date = new Date(); 
    var day = date.getDay();
    var day1 = date.getDate();
    day = ((day / 7) * 100);
    day1 = ((day1 / 30) * 100);
    const dayp = day + '%';
    const day1p = day1 + '%';

    const GetMemberShip = () => {
        postRequest(
            '/getbuymembershipdata',
            "",
            (response) => {
                setMemberShipPlan(response?.data?.data);
                setLoading(false)
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }


    useEffect(() => {
        GetMemberShip()
    }, [])


    if (loading) {
        return (
            <Button>Loading...</Button>
        )
    }
    
    return (
        <Card>
        <CardHeader title={"Membership"} />
        <CardContent sx={{ pt: 2 }}>
            <Stack direction={"row"} spacing={3}>
                <Grid item xs={6}>
                    <Typography variant={"h2"}>
                    </Typography>
                    <Typography variant={"body1"} sx={{ mb: 1 }}>Title: </Typography>
                    <Typography variant={"h3"}>Cloud Chaser</Typography>
                    <Stack direction={"row"} spacing={1} sx={{pt:2}}>
                        <Button variant={"contained"} color={"secondary"} component={Link} to={'/membership-plan'}>Buy Membership</Button>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"h5"} color={"text.secondary"} mb={2}>Membership Duration</Typography>
                    <Typography variant={"h3"} mb={2}>{memberShipPlan[0]?.duration}</Typography>
                    <Typography variant={"h6"} color={"text.primary"}  sx={{pt:1}}>Price
                        <Typography sx={{ borderLeft: 1, ml: 1, pl: 1 }} component={"span"}
                            color={"text.secondary"}>${memberShipPlan[0]?.price}</Typography>
                    </Typography>
                    <LinearProgress variant={"determinate"} color={"success"} value={day}
                        sx={{
                            width: { dayp },
                            borderRadius: 4,
                            height: 5,
                            mb: 2,
                            backgroundColor: '#E9EEEF'
                        }}
                    />
                  
                </Grid>
            </Stack>
        </CardContent>
    </Card>
    );
};

export default PortfolioBalance;
